import React from 'react';
import styled from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { Heading2 } from '@/components/Heading/Heading';
import { ALIGN } from '@/utils/constants';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';

export default ({ location }) => {
  return (
    <Layout location={location} noHeader noFooter>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            html, body, #___gatsby, #___gatsby > div { 
              width: 100%; height: 100%; background-color: #F8F8F8; 
            }
          `
        }}
      />
      <Metadata title="widget embed example" robots="noindex" />
      <Heading2 align={ALIGN.CENTER} marginTop="40px">
        Example iframe embed
      </Heading2>
      <Code>{code}</Code>
      {ReactHTMLParser(code)}
    </Layout>
  );
};

const code = `<iframe src="/soft-quote-widget/" width="100%" height="800px" title="Latitude widget" style="outline:none; border:none; margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline;"/>`;

const Code = styled.code`
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 16px;
  margin: 0 auto;
  background-color: #fff;
  cursor: pointer;
`;
